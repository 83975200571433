import React from 'react'
import Icon from 'react-icon-base'

const YeaLogo = props => (
  <Icon viewBox="0 0 938.7 128.4" {...props}>
    <g fill='none' fillRule='evenodd'>
      <path d="M100.36 7.0498H87.9601C87.4907 7.0498 87.1101 7.43036 87.1101 7.8998V20.2998C87.1101 20.7692 87.4907 21.1498 87.9601 21.1498H100.36C100.83 21.1498 101.21 20.7692 101.21 20.2998V7.8998C101.21 7.43036 100.83 7.0498 100.36 7.0498Z" fill="#0071E8"/>
      <path d="M81.5601 7.0498H69.1601C68.6906 7.0498 68.3101 7.43036 68.3101 7.8998V20.2998C68.3101 20.7692 68.6906 21.1498 69.1601 21.1498H81.5601C82.0295 21.1498 82.4101 20.7692 82.4101 20.2998V7.8998C82.4101 7.43036 82.0295 7.0498 81.5601 7.0498Z" fill="#0071E8"/>
      <path d="M94.94 25.9199H78.72C77.99 25.9199 77.39 26.4799 77.32 27.2099C73.21 70.4199 50.79 82.4699 4.74002 82.7799C4.38002 82.7799 4.03002 82.7799 3.68002 82.7799C2.90002 82.7799 2.27002 83.4199 2.27002 84.1999V100.29C2.27002 101.07 2.90002 101.7 3.69002 101.7C4.24002 101.7 4.75002 101.7 4.75002 101.7C58.71 101.4 90.65 84.3099 96.37 27.4799C96.45 26.6499 95.79 25.9199 94.96 25.9199H94.94Z" fill="#0071E8"/>
      <path d="M301.05 0H288.64C288.17 0 287.79 0.38 287.79 0.85V12.04H283.09V0.85C283.09 0.38 282.71 0 282.24 0H269.83C269.36 0 268.98 0.38 268.98 0.85V12.04H200.51C199.73 12.04 199.1 12.67 199.1 13.45V29.43C199.1 30.21 199.73 30.84 200.51 30.84H273.8C269.21 68.89 249.23 81 209.81 82.67C209.05 82.7 208.45 83.32 208.45 84.08V100.17C208.45 100.97 209.13 101.62 209.93 101.59C257.92 99.6 286.7 82.04 292.86 30.48V30.45C292.99 29.39 293.1 25.67 293.21 23.26H293.31V14.1H301.03C301.5 14.1 301.88 13.72 301.88 13.25V0.85C301.88 0.38 301.5 0 301.03 0H301.05Z" fill="#0071E8"/>
      <path d="M519.92 24.9702H503.7C502.97 24.9702 502.37 25.5302 502.3 26.2602C498.19 69.4702 475.77 81.5202 429.72 81.8302C429.36 81.8302 429.01 81.8302 428.66 81.8302C427.88 81.8302 427.25 82.4702 427.25 83.2502V99.3402C427.25 100.12 427.88 100.75 428.67 100.75C429.22 100.75 429.73 100.75 429.73 100.75C483.69 100.45 515.63 83.3602 521.35 26.5302C521.43 25.7002 520.77 24.9702 519.94 24.9702H519.92Z" fill="#0071E8"/>
      <path d="M428.6 28.7502C442.14 29.2702 462.12 30.9702 477.1 32.6102C477.94 32.7002 478.66 32.0502 478.66 31.2002V15.0102C478.66 14.2902 478.13 13.6802 477.41 13.6002C461.95 11.8602 442.98 10.5502 428.7 9.95023C427.9 9.92023 427.23 10.5602 427.23 11.3602V27.3302C427.23 28.0902 427.83 28.7102 428.59 28.7402L428.6 28.7502Z" fill="#0071E8"/>
      <path d="M406.45 19.9202H360.9V2.53018C360.9 2.06018 360.52 1.68018 360.05 1.68018H342.94C342.47 1.68018 342.09 2.06018 342.09 2.53018L342.05 19.9202H314.11C313.33 19.9202 312.7 20.5502 312.7 21.3302V37.3202C312.7 38.1002 313.33 38.7302 314.11 38.7302H342.01V39.0302C341.28 71.4502 325.65 74.7602 313.42 81.5202C312.97 81.7702 312.69 82.2502 312.69 82.7602V98.7802C312.69 99.7402 313.64 100.43 314.55 100.13C341.54 91.1502 360.41 78.2102 360.89 39.0202V38.7402H387.64C388.42 38.7402 389.05 39.3802 389.05 40.1602V71.2602C389.05 82.4602 389.05 81.5002 378.7 81.5002H362.34C361.56 81.5002 360.93 82.1302 360.93 82.9102V99.0402C360.93 99.8202 361.56 100.45 362.34 100.45H380.87C399.37 100.45 407.86 92.3102 407.86 72.6402V21.3302C407.86 20.5502 407.23 19.9202 406.45 19.9202Z" fill="#0071E8"/>
      <path d="M178.44 26.6602H117.6C116.82 26.6602 116.19 27.2902 116.19 28.0702V39.9002C116.19 40.6802 116.82 41.3102 117.6 41.3102H166.71V55.9802H122.49C121.71 55.9802 121.08 56.6102 121.08 57.3902V69.2202C121.08 70.0002 121.71 70.6302 122.49 70.6302H166.71V85.3002H117.66C116.85 85.3002 116.19 85.9602 116.19 86.7702V98.4902C116.19 99.3002 116.85 99.9602 117.66 99.9602H178.55C180.11 99.9602 181.38 98.6902 181.38 97.1302V29.6002C181.38 27.9802 180.06 26.6602 178.44 26.6602Z" fill="#0071E8"/>
      <path d="M3.61 26.1601C17.03 26.6801 36.78 28.1201 51.63 29.7601C52.46 29.8501 53.18 29.2001 53.18 28.3501V13.3201C53.18 12.6001 52.65 11.9901 51.94 11.9101C36.61 10.1701 17.87 9.14008 3.71 8.54008C2.91 8.51008 2.25 9.15008 2.25 9.95008V24.7301C2.25 25.4901 2.84 26.1101 3.6 26.1401L3.61 26.1601Z" fill="#0071E8"/>
      <path d="M50.92 57.1499V42.1199C50.92 41.3999 50.39 40.7899 49.68 40.7099C34.35 38.9699 15.61 37.9399 1.44999 37.3399C0.64999 37.3099 -0.0100098 37.9499 -0.0100098 38.7499V53.5299C-0.0100098 54.2899 0.57999 54.9099 1.33999 54.9399C14.76 55.4599 34.51 56.8999 49.36 58.5399C50.19 58.6299 50.91 57.9799 50.91 57.1299L50.92 57.1499Z" fill="#0071E8"/>
      <path d="M634.66 82.4998H605V101.17H596.89V82.4998H558.44V74.8898H572.05V52.5798H596.89V40.9198H577.54C573.91 47.1698 569.68 52.7498 565.37 56.9798C563.85 55.6298 560.47 53.0898 558.53 51.9098C566.3 45.2298 573.06 34.4998 576.95 23.2598L584.89 25.3698C583.88 27.9898 582.69 30.7798 581.6 33.3098H630.53V40.9198H605.01V52.5798H628.59V59.9298H605.01V74.8898H634.67V82.4998H634.66ZM596.89 74.8898V59.9298H579.9V74.8898H596.89Z" fill="#5A5A64"/>
      <path d="M696.93 65.7598C704.45 75.4798 716.37 84.7698 727.52 89.5898C725.66 91.2798 723.04 94.4098 721.69 96.5998C711.13 91.1098 700.23 81.5598 692.28 70.9998V101.08H684V71.4998C676.14 81.9798 665.24 91.4398 654.85 96.8498C653.41 94.8198 650.88 91.6898 649.02 90.0098C660.26 85.0198 672.26 75.7298 679.78 65.7598H655.78V58.0698H684V44.9698H651.64V37.1098H684V23.5898H692.28V37.1098H725.24V44.9698H692.28V58.0698H721.1V65.7598H696.93Z" fill="#5A5A64"/>
      <path d="M768.38 43.7899H741V37.5399H768.38V43.7899ZM816.21 91.7899C816.21 95.9299 815.37 98.3799 812.83 99.6499C810.38 100.92 806.15 101.09 799.99 101.09C799.74 99.0599 798.72 95.4299 797.79 93.3199C801.93 93.5699 806.07 93.4899 807.25 93.3999C808.52 93.3999 808.94 93.0599 808.94 91.7899V33.6499H779.28V57.4799C779.28 70.5799 778.35 89.4199 771.59 101.59C770.32 100.32 767.11 98.1299 765.42 97.2799L765.93 96.3499H750.55V100.07H744.3V71.6799H766.1V96.0199C771.68 84.7799 772.18 69.3199 772.18 57.4899V26.8999H816.21V91.7999V91.7899ZM766.18 55.1199H744.46V49.0399H766.18V55.1199ZM744.47 60.2699H766.19V66.4399H744.47V60.2699ZM766.01 32.5499H744.97V26.3799H766.01V32.5499ZM759.76 78.0199H750.55V90.1899H759.76V78.0199ZM790.69 54.3599V47.3499H783V41.4299H790.69V34.9199H796.94V41.4299H804.97V47.3499H796.94V54.3599H805.9V60.2799H782.32V54.3599H790.69ZM789.08 87.6499V90.7799H783.33V65.7699H804.54V87.6599H789.08V87.6499ZM789.08 71.5099V81.8199H798.71V71.5099H789.08Z" fill="#5A5A64"/>
      <path d="M906.32 38.8101C904.46 44.9801 901.76 50.1301 898.21 54.3601C902.27 57.6501 907.25 60.4401 913.25 62.1301C911.73 63.4801 909.7 66.4401 908.86 68.3001C902.69 66.1901 897.62 62.8901 893.4 59.1701C888.92 62.8901 883.43 65.8501 877.09 68.0401C876.33 66.6001 874.05 63.6501 872.61 62.3801C879.03 60.5201 884.44 57.8201 888.75 54.2701C886.21 51.2301 884.19 47.9301 882.58 44.7201C881.14 46.5801 879.62 48.2701 878.02 49.7901C877.09 48.6101 875.23 46.5801 873.71 45.1401V53.4201H861.2C865 55.1901 872.02 58.7401 874.13 59.9301L870.24 65.0001C868.04 63.3101 863.31 60.2701 859.51 58.0701V67.6201H852.58V58.1601C848.69 62.0501 843.88 65.6801 839.74 67.7901C838.73 66.3501 836.7 64.0701 835.18 62.8901C840.08 60.9501 845.74 57.2301 849.71 53.4301H838.98V37.7101H852.58V33.9901H836.78V28.5001H852.58V23.6001H859.51V28.5001H875.4V33.9901H859.51V37.7101H873.71V43.5401C879.03 38.6401 883 31.4601 885.12 23.6001L892.3 25.2901C891.54 27.8301 890.61 30.2801 889.6 32.6401H911.82V38.8101H906.33H906.32ZM877.67 93.0601H912.15V99.5701H836.01V93.0601H849.19V79.3701H856.63V93.0601H869.98V75.9901H841.33V69.7401H906.74V75.9901H877.67V81.4001H900.82V87.3201H877.67V93.0701V93.0601ZM845.22 48.4401H852.57V42.5201H845.22V48.4401ZM867.2 42.5201H859.51V48.4401H867.2V42.5201ZM886.46 38.8901C888.07 42.6101 890.26 46.2401 893.3 49.6201C895.75 46.5801 897.61 42.9401 898.88 38.8001H886.46V38.8801V38.8901Z" fill="#5A5A64"/>
    </g>
  </Icon>
)
YeaLogo.defaultProps = {
  size: 160
}

export default YeaLogo
