import React from 'react'
import Icon from 'react-icon-base'

const AlertCustomizeCheckCircle = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill='none' fillRule='evenodd' stroke="white" strokeWidth="3.2" strokeLinecap="butt" strokeLinejoin="butt">
      <circle cx="12" cy="12" r="12" fill="#3dc75d" stroke="none"/>
      <path d="M6.5 11.4L10.5 15.5L18.2 8.1"/>
    </g>
  </Icon>
)

AlertCustomizeCheckCircle.defaultProps = {
  size: 18
}

export default AlertCustomizeCheckCircle
