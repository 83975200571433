import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Office, Question, YeaLogo, User, Pulldown } from 'jbc-front/components/icons';
import styles from './Header.scss';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { year } from '@jbc-year-end-adj/2024/consts';
import { recordDisplay } from '@jbc-year-end-adj/2024/utils/recordDisplay';
import Select from 'react-select';
import PullDownMenu from '@jbc-year-end-adj/2024/components/PullDownMenu';
import classNames from 'classnames';

const THEME_COLOR = '#3498db';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? THEME_COLOR : '#ffffff',
    color: state.isFocused ? '#ffffff' : '#333'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    width: '30px'
  }),
  container: provided => ({
    ...provided,
    width: '90px',
    height: '30px',
    marginLeft: '10px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    width: '30px',
    height: '30px'
  }),
  control: provided => ({
    ...provided,
    boxShadow: null,
    width: '90px',
    height: '30px'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 8px'
  })
};

const customTheme = theme => ({
  ...theme,
  borderRadius: '4px',
  colors: {
    ...theme.colors,
    primary50: THEME_COLOR,
    primary25: THEME_COLOR,
    primary: THEME_COLOR
  },
  spacing: {
    ...theme.spacing,
    controlHeight: '30px'
  }
});

const NotVerified = () => (
  <div className={styles.notVerified}>
    メール送信機能が制限されています。
    <br />
    機能解放にはご利用の労務HR・給与計算でのお手続きが必要です。
  </div>
);

export const Header = ({ logo, client, currentUser = {}, showNavi }) => {
  const { office, me, clientYearly, clientYearlies } = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const options = useMemo(
    () =>
      (clientYearlies || []).map(clientYearly => ({
        value: `${clientYearly.year}`,
        label: `${clientYearly.year}年`
      })),
    [clientYearlies]
  );
  const toggleDropdownMenu = () => setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);

  return (
    <React.Fragment>
      <div className={styles.base}>
        <div className={styles.logo}>
          <YeaLogo className={styles.brand} />
        </div>
        {showNavi && (
          <>
            {me?.application && !me.application.verified && <NotVerified />}
            <div className={styles.help}>
              <a
                className={styles.menu}
                target="_blank"
                rel="noopener noreferrer"
                href="https://jobcan-payroll.zendesk.com/hc/ja/categories/360001399833"
              >
                <Question size={20} />
                ヘルプ
              </a>
            </div>
            <div className={styles.client}>
              <Office size={30} />
              <Link to={`/${year ? year + '/' : ''}office`} className={styles.link}>
                {recordDisplay(office?.name)}
              </Link>
              <Select
                styles={customStyles}
                theme={customTheme}
                openOnFocus={true}
                closeOnSelect={false}
                onChange={({ value }) => {
                  const clientYearly = clientYearlies.find(clientYearly => clientYearly.year === +value);
                  window.location.href = clientYearly.fixed ? `/${value}/result` : `/${value}`;
                  setIsOpen(false);
                }}
                placeholder=""
                value={options.find(option => option.value === `${year || clientYearly?.year}`)}
                disabled={false}
                isClearable={false}
                menuIsOpen={isOpen}
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
                components={{ LoadingIndicator: () => null }}
                options={options}
              />
            </div>
            <div className={styles.user}>
              <div onClick={toggleDropdownMenu} onKeyDown={toggleDropdownMenu} role="button" tabIndex={0}>
                <User size={30} />
                <a target="_blank" className={styles.email}>
                  {me?.name}&nbsp;様&nbsp;
                </a>
                <span className={styles.pulldown}>
                  <Pulldown size={10} />
                  &nbsp;
                </span>
              </div>

              {isMenuOpen && (
                <PullDownMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
                  <div className="dd-menu-items">
                    <ul key="items" className="dd-items-right">
                      <li className={classNames(styles.customerCodeStyle)}>
                        問い合わせ番号
                        {me?.customerCode && <span>{me?.customerCode}</span>}
                      </li>
                    </ul>
                  </div>
                </PullDownMenu>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
