import React from 'react'
import Icon from 'react-icon-base'

const Healthcare = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="currentColor">
      <path d="M16.2 20H3.8C2.3 20 1 18.7 1 17.2V2.8C1 1.3 2.3 0 3.8 0h12.4C17.7 0 19 1.3 19 2.8v14.3c0 1.5-1.3 2.8-2.8 2.8zM3.8 1.6c-.7 0-1.2.5-1.2 1.2v14.3c0 .7.5 1.2 1.2 1.2h12.4c.7 0 1.2-.5 1.2-1.2V2.8c0-.7-.5-1.2-1.2-1.2z"/>
      <path d="M14.7 8.9H4.9c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h9.8c.4 0 .8.4.8.8s-.4.8-.8.8m0-3.5H4.9c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h9.8c.4 0 .8.4.8.8s-.4.8-.8.8M14.7 14.4h-4.4c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h4.4c.4 0 .8.4.8.8s-.4.8-.8.8"/>
      <path d="M12.5 16.6c-.4 0-.8-.4-.8-.8v-4.4c0-.4.4-.8.8-.8s.8.4.8.8v4.4c0 .4-.4.8-.8.8"/>
    </g>
  </Icon>
)

Healthcare.defaultProps = {
  size: 20
}

export default Healthcare
